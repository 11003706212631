var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "关联作业票",
            visible: _vm.ticketBox,
            width: "90%",
            top: "10vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.ticketBox = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c("grid-layout", {
            ref: "gridLayOut",
            attrs: {
              "table-options": _vm.option,
              "table-data": _vm.tableData,
              "table-loading": _vm.loading,
              "data-total": _vm.page.total,
              "grid-row-btn": [],
              page: _vm.page,
            },
            on: {
              "page-current-change": _vm.onLoad,
              "page-size-change": _vm.onLoad,
            },
            scopedSlots: _vm._u([
              {
                key: "customBtn",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { margin: "0 3px" },
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.selectRow(row)
                          },
                        },
                      },
                      [_vm._v("选择")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }