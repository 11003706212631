<template>
    <div>
        <el-dialog title="文件上传" :visible.sync="fileBox" width="30%" top="10vh"
            @close="closePersonDialog">
            <el-upload class="upload-demo" action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km"
                :show-file-list="true" :limit="1" :on-exceed="handleExceed" :on-success="handleAvatarSuccess" :on-remove="handleRemove"
                :file-list="fileList" ref="uploadRef" :headers="headers">
                <el-button size="small" type="primary" icon="el-icon-upload">点击上传
                </el-button>
            </el-upload>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closePersonDialog">{{
            $t("cip.cmn.btn.celBtn")
        }}</el-button>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="submit">
                    确定
                </el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import { getToken } from "@/util/auth";
export default {
    data() {
        return {
            fileBox: true,
            fileList: []
        };
    },

    components: {},

    computed: {
        headers: function () {
            return { "Sinoma-Auth": getToken() };
        },
    },

    mounted() { },

    methods: {
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件`);
        },
        handleAvatarSuccess(res, file, fileList) {
            if (res.code == 200) {
                this.fileList.push({
                    name: res.data.originalName,
                    url: res.data.link,
                });
                this.$message({
                    type: "success",
                    message: "上传成功！",
                });
            }
        },
        handleRemove(file, fileList){
           this.fileList = []
        },
        closePersonDialog() {
            this.$emit('selectFile', [])
            this.fileBox = false
        },
        submit() {
            this.$emit('selectFile', this.fileList)
            this.fileBox = false
        }
    },
}
</script>

<style scoped lang='scss'>
::v-deep .el-dialog__footer {
    text-align: center;
}
</style>