var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "作业票签署",
            visible: _vm.signBox,
            width: "50%",
            top: "10vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.signBox = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c("div", { staticClass: "sign-contaniner" }, [
            _c(
              "div",
              { staticClass: "sign-content", staticStyle: { width: "100%" } },
              [
                _c("div", { staticClass: "text" }, [
                  _vm._v("请在下面框线区域绘制签名"),
                ]),
                _c("div", { staticClass: "canvas" }, [
                  _c("canvas", { staticStyle: { display: "block" } }),
                ]),
                _c(
                  "div",
                  { staticClass: "btn-content" },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-right": "10px" },
                        attrs: { type: "primary" },
                        on: { click: _vm.selectSing },
                      },
                      [_vm._v("使用默认签名")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-right": "10px" },
                        attrs: { type: "primary" },
                        on: { click: _vm.handleClear },
                      },
                      [_vm._v("重写")]
                    ),
                    _c(
                      "el-checkbox",
                      {
                        model: {
                          value: _vm.checked,
                          callback: function ($$v) {
                            _vm.checked = $$v
                          },
                          expression: "checked",
                        },
                      },
                      [_vm._v("设置为默认签名")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn"))),
              ]),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.submit } }, [
                _vm._v("\n                确定\n            "),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }