<template>
    <div style="height: 100%;">
        <Editor v-if="refreshEditor" :mode="mode" @after-init.native="onAfterInit" @doc-loaded.native="onDocLoaded"
            width="100%"></Editor>
        <DatePicker v-if="dialogVisible" @selectedDate="selectedDate" />
        <Personnel v-if="showPerson" @selectedPerson="selectedPerson" :disabledList="disabledList" />
        <Dept v-if="showDept" @selectedDept="selectedDept" />
        <FileUpload v-if="showFile" @selectFile="selectFile" />
        <WorkTicket v-if="showWorkTicket" @selectWorkTicket="selectWorkTicket" />
        <Sign v-if="showSign" @selectSign="selectSign" />
        <Video v-if="showVideo" @selectVideo="selectVideo" />
        <Audio v-if="showAudio" @selectAudio="selectAudio" />
    </div>
</template>

<script>
import Editor from './components/Editor.vue'
import DatePicker from './components/DatePicker.vue'
import Personnel from './components/Personnel.vue'
import Dept from './components/Dept.vue'
import FileUpload from './components/FileUpload.vue'
import WorkTicket from './components/WorkTicket.vue'
import Sign from "./components/Sign.vue"
import Video from "./components/Video.vue"
import Audio from "./components/Audio.vue"
import snows from "@/util/snows"
import { downloadFileBlob } from "@/util/util";
export default {
    components: { 'Editor': Editor, DatePicker, Personnel, Dept, FileUpload, WorkTicket, Sign, Video, Audio },
    props: {
        mode: {
            type: 'String',
            default: 'design'
        },
        loadHtml: {
            type: 'String',
            default: ''
        }
    },
    data() {
        return {
            refreshEditor: true,
            editor: null, //第1个文档实例
            dialogVisible: false,
            selectedDomId: '',
            showPerson: false,
            disabledList: [],
            showDept: false,
            showFile: false,
            showWorkTicket: false,
            showSign: false,
            showVideo: false,
            showAudio: false
        }
    },
    watch: {
        mode(val) {
            this.refreshEditor = false;
            this.$nextTick(() => {
                this.refreshEditor = true
            })
        },
        loadHtml(val) {
            if (val) {
                this.refreshEditor = false;
                this.$nextTick(() => {
                    this.refreshEditor = true
                    this.onAfterInit()
                })
            }
        }
    },
    mounted() {
        window.addEventListener("message", this.handleMessage, false);
    },
    methods: {
        selectAudio(audioObj) {
            this.showAudio = false;
            if (audioObj) {
                var html = `<audio  src="${audioObj.videoUrl}" controls="${audioObj.controls}"></audio>&nbsp;`
                if(audioObj.autoplay) html = `<audio  src="${audioObj.videoUrl}" controls="${audioObj.controls}" autoplay="${audioObj.autoplay}"></audio>&nbsp;`
                this.editor.control.insertHTML(html)
            }
        },
        selectVideo(videoObj) {
            this.showVideo = false;
            if (videoObj) {
                var html = `<video width="${videoObj.width}" height="${videoObj.height}" src="${videoObj.videoUrl}"></video>&nbsp;`
                if(videoObj.controls) html = `<video width="${videoObj.width}" height="${videoObj.height}" src="${videoObj.videoUrl}" controls="${videoObj.controls}"></video>&nbsp;`
                if(videoObj.autoplay) html = `<video width="${videoObj.width}" height="${videoObj.height}" src="${videoObj.videoUrl}" autoplay="${videoObj.autoplay}"></video>&nbsp;`
                if(videoObj.controls && videoObj.autoplay) html = `<video width="${videoObj.width}" height="${videoObj.height}" src="${videoObj.videoUrl}" controls="${videoObj.controls}" autoplay="${videoObj.autoplay}"></video>&nbsp;`
                this.editor.control.insertHTML(html)
            }
        },
        selectedDate(val) {
            this.dialogVisible = false
            let time = val ? val[0] + '-' + val[1] : ''
            if (this.selectedDomId && time) this.editor.setBindObject(time, this.selectedDomId)
        },
        selectedPerson(val) {
            this.showPerson = false
          if(val.length > 0){
            let names = [];
            let userIds = [];
            val.forEach(item => {
              names.push(item.realName)
              userIds.push(item.id)
            });
            let nameStr = names.join(',')
            let userIdStr = userIds.join(',')
            if (this.selectedDomId && nameStr) this.editor.setBindObject(nameStr, this.selectedDomId);
            this.editor.$(`#${this.selectedDomId}`).attr('userIds', userIdStr)
          }
        },
        selectedDept(val) {
            this.showDept = false
            let names = [];
            val.forEach(item => {
                names.push(item.deptName)
            });
            let nameStr = names.join(',')
            if (this.selectedDomId && nameStr) this.editor.setBindObject(nameStr, this.selectedDomId);
            // this.editor.$(`#${this.selectedDomId}`).attr('deptInfo', JSON.stringify(val))
        },
        selectFile(val) {
            this.showFile = false;
            if (this.selectedDomId && val.length > 0) this.editor.setBindObject(val[0].name, this.selectedDomId);
            this.editor.$(`#${this.selectedDomId}`).attr('fileUrl', val[0].url);
            this.editor.$(`#${this.selectedDomId}`).attr('downloadName', val[0].name);
        },
        selectWorkTicket(val) {
            this.showWorkTicket = false;
            let nameStr = val.$type + '-' + val.code
            if (this.selectedDomId && val) this.editor.setBindObject(nameStr, this.selectedDomId);
        },
        selectSign(val) {
            this.showSign = false;
            if (this.selectedDomId && val) this.editor.$(`#${this.selectedDomId}`).attr('src', val);
        },
        handleMessage(event) {
            if (event.data.eventName == 'selectDatePick' || event.data.eventName == 'workExecutionTime') {
                this.selectedDomId = event.data.msg
                this.dialogVisible = true
            } else if (event.data.eventName == 'selectPerson' || event.data.eventName == 'workSupervisor') {
                this.selectedDomId = event.data.msg
                this.showPerson = true
            } else if (event.data.eventName == 'selectDept') {
                this.selectedDomId = event.data.msg
                this.showDept = true
            } else if (event.data.eventName == 'selectFile') {
                this.selectedDomId = event.data.msg
                this.showFile = true
            } else if (event.data.eventName == 'selectWorkTicket') {
                this.selectedDomId = event.data.msg
                this.showWorkTicket = true
            } else if (event.data.eventName == 'selectSign') {
                this.selectedDomId = event.data.msg
                this.showSign = true
            } else if (event.data.eventName == 'selectVideo') {
                this.showVideo = true
            } else if (event.data.eventName == 'selectAudio') {
                this.showAudio = true
            } else if (event.data.eventName == 'selectAppFile') {
                this.selectedDomId = event.data.msg
                this.showFile = true
            }
        },
        //初始化文档
        onAfterInit(e) {
            if (e) {
                this.$emit('onAfterInit')
                this.editor = e.editor
                if (this.loadHtml) {
                    this.editor.loadHtml(this.loadHtml)
                    this.onDocLoaded()
                }
            }
        },

        //文档加载后
        onDocLoaded() {
            this.$emit('docLoaded')
            //日期控件
            if (this.mode !== 'readonly') {
                let datePickerDoms = this.editor.$("[id*='datePick']");
                datePickerDoms.each((index, item) => {
                    this.editor.$(item).click((e) => {
                        this.selectedDomId = e.target.id
                        this.dialogVisible = true
                    })
                })
                this.editor.$("#workExecutionTime").click((e) => {
                    this.selectedDomId = e.target.id
                    this.dialogVisible = true
                })
                //人员控件
                let userDoms = this.editor.$("[id*='user']");
                userDoms.each((index, item) => {
                    this.editor.$(item).click((e) => {
                        this.selectedDomId = e.target.id
                        this.showPerson = true
                    })
                })
                this.editor.$("#workSupervisor").click((e) => {
                    this.selectedDomId = e.target.id
                    this.showPerson = true
                })
                this.editor.$("#workTicket").click((e) => {
                    this.selectedDomId = e.target.id
                    this.showWorkTicket = true
                })
                //部门
                let deptDoms = this.editor.$("[id*='dept']");
                deptDoms.each((index, item) => {
                    this.editor.$(item).click((e) => {
                        this.selectedDomId = e.target.id
                        this.showDept = true
                    })
                })
                //文件
                let fileDoms = this.editor.$("[id*='uploadFile']");
                fileDoms.each((index, item) => {
                    this.editor.$(item).click((e) => {
                        if (this.mode == 'design') {
                            this.selectedDomId = e.target.id
                            this.showFile = true
                        } else {
                            if(e.target.attributes.fileurl){
                            let fileUrl = e.target.attributes.fileurl.value;
                            let name = e.target.attributes.downloadName.value;
                            downloadFileBlob(fileUrl,name)
                            }
                        }
                    })
                })
                //app文件上传
                let appFileDoms = this.editor.$("[id*='appUploadFile']");
                appFileDoms.each((index, item) => {
                  this.editor.$(item).click((e) => {
                    if (this.mode == 'design') {
                      this.selectedDomId = e.target.id
                      this.showFile = true
                    } else {
                      if(e.target.attributes.fileurl){
                        let fileUrl = e.target.attributes.fileurl.value;
                        let name = e.target.attributes.downloadName.value;
                        downloadFileBlob(fileUrl,name)
                      }
                    }
                  })
                })
                //签名控件
                let signDoms = this.editor.$("[id*='sign']");
                signDoms.each((index, item) => {
                    this.editor.$(item).click((e) => {
                        this.selectedDomId = e.target.id
                        this.showSign = true
                    })
                })
                //控件库控件事件处理
                //user业务域
                let userDom = this.editor.$("[domain='user']");
                userDom.each((index, item) => {
                    this.editor.$(item).click((e) => {
                        this.selectedDomId = e.target.id
                        this.showPerson = true
                    })
                })
                //dept业务域
                let deptDom = this.editor.$("[domain='dept']");
                deptDom.each((index, item) => {
                    this.editor.$(item).click((e) => {
                        this.selectedDomId = e.target.id
                        this.showDept = true
                    })
                })
                //datePick业务域
                let datePickDom = this.editor.$("[domain='datePick']");
                datePickDom.each((index, item) => {
                    this.editor.$(item).click((e) => {
                        this.selectedDomId = e.target.id
                        this.dialogVisible = true
                    })
                })
            }
        },
        //执行文档命令
        execCommand: function (cmd) {
            this.editor.execCommand(cmd)
        },
    },
    destroyed() {
        // 清除popstate事件 否则会影响到其他页面
        window.removeEventListener("message", this.handleMessage, false);
    },
}
</script>
