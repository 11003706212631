<template>
    <el-dialog title="日期范围选择" :visible.sync="showDialog" width="30%" @close="closeDialog">
        <el-date-picker v-model="dateTime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy年MM月dd日 HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" @click="saveDate">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    name: 'DatePicke',
    data() {
        return {
            dateTime: '',
            showDialog: true
        };
    },

    components: {
    },

    computed: {},

    mounted() {
    },

    methods: {
        saveDate(){
            if(this.dateTime){
               this.$emit('selectedDate',this.dateTime)
               this.showDialog = false
            }else{
                this.$message('请选择日期范围');
            }
        },
        closeDialog(){
            this.$emit('selectedDate','')
            this.showDialog = false
        }
    }
}
</script>

<style scoped>
::v-deep .el-dialog__footer{
    text-align: center;
}
</style>