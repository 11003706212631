var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "部门选择",
            visible: _vm.showDept,
            width: "50%",
            top: "10vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDept = $event
            },
            close: _vm.closeDeptDialog,
          },
        },
        [
          _c(
            "el-input",
            {
              staticClass: "search-input",
              attrs: { placeholder: "请输入关键词查询", clearable: "" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.search(_vm.keyword, _vm.componylist)
                },
              },
              model: {
                value: _vm.keyword,
                callback: function ($$v) {
                  _vm.keyword = $$v
                },
                expression: "keyword",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                on: {
                  click: function ($event) {
                    return _vm.search(_vm.keyword, _vm.componylist)
                  },
                },
                slot: "append",
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "shutContent" },
            [
              _c("el-tree", {
                ref: "tree",
                attrs: {
                  data: _vm.componylist,
                  "node-key": "id",
                  props: _vm.defaultProps,
                  "check-strictly": true,
                  "default-expand-all": "",
                  "default-checked-keys": _vm.defaultCheckedKeys,
                },
                on: { "node-click": _vm.nodeClick },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ node }) {
                      return _c(
                        "span",
                        {
                          staticClass: "custom-tree-node",
                          attrs: { title: node.label },
                        },
                        [
                          _c("span", { staticClass: "text" }, [
                            _vm._v(_vm._s(node.label)),
                          ]),
                        ]
                      )
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDeptDialog } }, [
                _vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn"))),
              ]),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.submit } }, [
                _vm._v("\n                确定\n            "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }