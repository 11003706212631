var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.roleBox,
            width: "90%",
            top: "10vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.roleBox = $event
            },
            close: _vm.closePersonDialog,
          },
        },
        [
          _c("UserDeptMultipleDialog", {
            ref: "UserDeptMultipleDialog",
            on: {
              closeDia: _vm.closePersonDialog,
              "select-all-data": _vm.selectAllData,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }