var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择视频",
            visible: _vm.videoBox,
            width: "30%",
            top: "10vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.videoBox = $event
            },
            close: _vm.closeVideoDialog,
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "文件上传" } },
                [
                  _c("SingleFIleUpload", {
                    ref: "SingleFIleUpload",
                    attrs: { showView: false, accept: "", fileSize: "2000" },
                    on: {
                      fileIds: _vm.handleAvatarSuccess,
                      handleRemove: _vm.handleRemove,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "宽度" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0 },
                    model: {
                      value: _vm.form.width,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "width", $$v)
                      },
                      expression: "form.width",
                    },
                  }),
                  _vm._v("像素\n                "),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "高度" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0 },
                    model: {
                      value: _vm.form.height,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "height", $$v)
                      },
                      expression: "form.height",
                    },
                  }),
                  _vm._v("像素\n                "),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "自动播放" } },
                [
                  _c("el-checkbox", {
                    model: {
                      value: _vm.form.autoplay,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "autoplay", $$v)
                      },
                      expression: "form.autoplay",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "播放控件" } },
                [
                  _c("el-checkbox", {
                    model: {
                      value: _vm.form.controls,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "controls", $$v)
                      },
                      expression: "form.controls",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeVideoDialog } }, [
                _vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn"))),
              ]),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.submit } }, [
                _vm._v("\n                确定\n            "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }