<template>
    <div>
        <el-dialog title="部门选择" :visible.sync="showDept" width="50%" top="10vh" @close="closeDeptDialog">
            <el-input placeholder="请输入关键词查询" v-model="keyword" @keyup.enter.native="search(keyword, componylist)"
                clearable class="search-input">
                <el-button slot="append" icon="el-icon-search" @click="search(keyword, componylist)"></el-button>
            </el-input>
            <div class="shutContent">
                <el-tree :data="componylist" node-key="id" ref="tree" :props="defaultProps"
                    :check-strictly="true" default-expand-all :default-checked-keys="defaultCheckedKeys"
                    @node-click="nodeClick">
                    <span class="custom-tree-node" slot-scope="{ node }" :title="node.label">
                        <span class="text">{{ node.label }}</span>
                    </span>
                </el-tree>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDeptDialog">{{
            $t("cip.cmn.btn.celBtn")
        }}</el-button>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="submit">
                    确定
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { selectChildren } from "@/api/system/dept";
export default {
    data() {
        return {
            showDept: true,
            keyword: '',
            componylist: [],
            defaultProps: {
                children: "children",
                label: "fullName",
                value: "id",
            },
            defaultCheckedKeys: [],
            checkedNode: []
        };
    },

    components: {},

    computed: {},

    mounted() { },
    created() {
        this.getcomponylist()
    },
    methods: {
        getcomponylist() {
            selectChildren().then((res) => {
                this.componylist = res.data.data;
            })
        },
        search(val, tree, newArr = []) {
            if (val == '') {
                this.getData();
                return;
            }
            if (!(tree.length && val)) {
                return tree
            }
            for (let item of tree) {
                if (item.fullName.indexOf(val) > -1) {
                    newArr.push(item)
                    continue
                }
                if (item.children && item.children.length) {
                    let subArr = this.search(val, item.children)
                    if (subArr && subArr.length) {
                        let node = {
                            ...item,
                            children: subArr
                        }
                        newArr.push(node)
                    }
                }
            }
            this.componylist = newArr;
            return newArr
        },
        handleCheckChange(node, nodeKey) {
            node.checked = nodeKey;
            // if (node.children && node.children.length > 0) {
            //     node.children.forEach(child => {
            //         this.$refs.tree.setChecked(child, node.checked);
            //     });
            // }
            this.checkedNode = this.$refs.tree.getCheckedNodes();
        },
        nodeClick(node){
            this.checkedNode = [node]
        },
        closeDeptDialog(){
            this.$emit('selectedDept',[])
            this.showDept = false
        },
        submit(){
            this.$emit('selectedDept',this.checkedNode)
            this.showDept = false
        }
    },
}
</script>

<style scoped lang='scss'>
.shutContent {
    max-height: 500px;
    overflow-y: auto;
    margin-top: 10px;
}

::v-deep .el-dialog__body {
    padding: 10px 20px;
}
</style>