var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "iframe",
    _vm._b(
      { ref: "iframe", attrs: { src: "editor_emr.html" } },
      "iframe",
      _vm.objectOfAttrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }