var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "文件上传",
            visible: _vm.fileBox,
            width: "30%",
            top: "10vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.fileBox = $event
            },
            close: _vm.closePersonDialog,
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "uploadRef",
              staticClass: "upload-demo",
              attrs: {
                action:
                  "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km",
                "show-file-list": true,
                limit: 1,
                "on-exceed": _vm.handleExceed,
                "on-success": _vm.handleAvatarSuccess,
                "on-remove": _vm.handleRemove,
                "file-list": _vm.fileList,
                headers: _vm.headers,
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-upload",
                  },
                },
                [_vm._v("点击上传\n            ")]
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closePersonDialog } }, [
                _vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn"))),
              ]),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.submit } }, [
                _vm._v("\n                确定\n            "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }