var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择音频",
            visible: _vm.audioBox,
            width: "30%",
            top: "10vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.audioBox = $event
            },
            close: _vm.closeAudioDialog,
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "文件上传" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "uploadRef",
                      staticClass: "upload-demo",
                      attrs: {
                        accept: "audio/*",
                        "before-upload": _vm.beforeUpload,
                        action:
                          "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km",
                        "show-file-list": true,
                        limit: 1,
                        "on-exceed": _vm.handleExceed,
                        "on-success": _vm.handleAvatarSuccess,
                        "on-remove": _vm.handleRemove,
                        "file-list": _vm.fileList,
                        headers: _vm.headers,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "small",
                            type: "primary",
                            icon: "el-icon-upload",
                          },
                        },
                        [_vm._v("点击上传\n                    ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "自动播放" } },
                [
                  _c("el-checkbox", {
                    model: {
                      value: _vm.form.autoplay,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "autoplay", $$v)
                      },
                      expression: "form.autoplay",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeAudioDialog } }, [
                _vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn"))),
              ]),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.submit } }, [
                _vm._v("\n                确定\n            "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }