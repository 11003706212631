<template>
    <div>
        <el-dialog title="作业票签署" :visible.sync="signBox" width="50%" top="10vh" @close="closeDialog">
            <div class="sign-contaniner">
                <!-- <div class="sign-content" style="width:40%;padding-right:20px">
                    <div class="text">方式一</div>
                    <div class="text">请用微信/手机浏览器扫码，在移动端完成签名</div>
                    <div class="code-content">
                        <img src="@/assets/images/login_qr.png" alt="">
                    </div>
                </div> -->
                <div class="sign-content" style="width:100%">
                    <!-- <div class="text">方式二</div> -->
                    <div class="text">请在下面框线区域绘制签名</div>
                    <div class="canvas">
                        <canvas style="display: block;"></canvas>
                    </div>
                    <div class="btn-content">
                        <el-button type="primary" @click="selectSing" style="margin-right: 10px;">使用默认签名</el-button>
                        <!-- <el-button type="primary">使用默认签名</el-button> -->
                        <el-button type="primary" @click="handleClear" style="margin-right: 10px;">重写</el-button>
                        <el-checkbox v-model="checked">设置为默认签名</el-checkbox>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">{{
            $t("cip.cmn.btn.celBtn")
        }}</el-button>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="submit">
                    确定
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import SmoothSignature from "smooth-signature";
import { mapGetters } from "vuex";
import { putFile,updateElectronicSignature } from "@/api/system/user";
export default {
    data() {
        return {
            signBox: true,
            signature: null,
            checked: false
        };
    },

    components: {},

    computed: {
        ...mapGetters(['userInfo']),
    },

    mounted() {
        setTimeout(() => {
            this.init()
        }, 1000)
    },

    methods: {
        handleClear() {
            this.signature.clear();
        },
        init() {
            const canvas = document.querySelector("canvas");
            const options = {
                // width: 100,
                // height: 250,
                minWidth: 4,
                maxWidth: 12,
                // color: '#1890ff',
                bgColor: '#f6f6f6'
            };

            this.signature = new SmoothSignature(canvas, options);
            window.addEventListener("resize", function () {
               this.init();
            });
        },
        closeDialog() {
            this.$emit('selectSign', '')
            this.signBox = false
        },
        selectSing() {
            if (this.userInfo.electronicSignature) {
                this.$emit('selectSign', this.userInfo.electronicSignature)
                this.signBox = false
            } else {
                this.$message({
                    type: 'warning',
                    message: '当前用户没有绘制签名，请绘制签名'
                })
            }
        },
        base64toFile(urlString, fileName) {
            const dataArr = urlString.split(",");
            const byteString = atob(dataArr[1]);
            const options = {
                type: "image/png",
                endings: "native"
            };
            const u8Arr = new Uint8Array(byteString.length);
            for (let i = 0; i < byteString.length; i++) {
                u8Arr[i] = byteString.charCodeAt(i);
            }
            return new File([u8Arr], fileName + ".png", options);//返回文件流
        },
        submit() {
            const pngUrl = this.signature.getPNG();
            if (this.checked) {
                let fileName = 'electronicSignature'+ new Date()
                let file = this.base64toFile(pngUrl,fileName)
                const formData = new FormData()
                formData.append('file', file);
                putFile(formData).then((res) => {
                    let params = {
                        id:this.userInfo.user_id,
                        electronicSignature: res.data.data.link
                    }
                    updateElectronicSignature(params).then(res => {
                        this.$store.commit('SET_USER_INFO', {
                            ...this.userInfo,
                            electronicSignature: params.electronicSignature,
                        });
                    })
                })
            }
            this.$emit('selectSign', pngUrl)
            this.signBox = false
        }
    },
}
</script>

<style scoped lang='scss'>
.sign-contaniner {
    display: flex;

    .sign-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .text {
            line-height: 30px;
        }

        .code-content {
            width: 200px;
            height: 200px;
            margin-top: 50px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .canvas {
            width: 100%;
            height: 280px;
            border: 1px dashed #ccc;
            canvas {
                width: 100%;
                height: 100%;
            }
        }

        .btn-content {
            padding: 12px 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }
}
</style>