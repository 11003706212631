<template>
    <div>
        <el-dialog title="人员选择" :visible.sync="roleBox" width="90%"
            top="10vh" @close="closePersonDialog">
            <!-- <departmentPersonnel ref="departmentPersonnel" v-if="roleBox" :disabled-list="disabledErpItemList">
            </departmentPersonnel> -->
            <UserDeptMultipleDialog ref="UserDeptMultipleDialog" @closeDia="closePersonDialog" @select-all-data="selectAllData"></UserDeptMultipleDialog>
            <!-- <span slot="footer" class="dialog-footer">
                <el-button @click="closePersonDialog">{{
            $t("cip.cmn.btn.celBtn")
        }}</el-button>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="submit">
                    确定
                </el-button>
            </span> -->
        </el-dialog>
    </div>
</template>

<script>
// import departmentPersonnel from "@/views/business/training/exam/departmentPersonnel.vue";
import UserDeptMultipleDialog from "@/views/components/UserDeptDialog/UserDeptMultipleDialog"
export default {
    props: {
        disabledList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            roleBox: true
        };
    },

    components: {
        // departmentPersonnel
        UserDeptMultipleDialog
    },

    computed: {
        disabledErpItemList(){
            return this.disabledList
        }
    },

    mounted() { 
    },

    methods: {
        closePersonDialog(){
            this.$emit('selectedPerson',[])
            this.roleBox = false
        },
        submit(){
            //多选人员处理
            let confirmTheSubmission = this.$refs.departmentPersonnel.confirmTheSubmission();
            // if(confirmTheSubmission.length > 0){
            //     this.disabledErpItemList = confirmTheSubmission.map(e => e.userId + "" + e.deptId);
            // }
            this.$emit('selectedPerson',confirmTheSubmission)
            this.roleBox = false
        },
        selectAllData(obj) {
            let confirmTheSubmission = obj.selectAllData;
            this.$emit('selectedPerson',confirmTheSubmission)
            this.roleBox = false
        }
    },
}
</script>

<style scoped lang='scss'></style>